<template>
    <v-row justify="center" class="py-5" style="height: 100%" align="top">
        <v-col cols="12" sm="6">
            <!-- <v-row no-gutters justify="center">
                <v-col cols="4">
                <template v-if="brandselector === 'hostname'">
                    <v-img v-if="!brandprofile" alt="Unicorn Springs" src="@/assets/logo/unicornsprings/UnicornSprings_light_264x48.png" width="196" height="36" fit="inside"></v-img>
                    <BrandImage v-if="brandprofile" :brandprofile="brandprofile" intent="logotype" mode="light" :height="36" fit="inside" contain position="center"/>
                </template>
                <template v-if="brandselector === 'brandprofile'">
                    <BrandImage v-if="brandprofile" :brandprofile="brandprofile" intent="logotype" mode="light" :height="36" fit="inside" contain position="center"/>
                </template>
                </v-col>
            </v-row> -->

            <v-form @submit.prevent="submitForm" onSubmit="return false;" @keyup.enter.native.prevent="submitForm" class="mt-6">
            <p class="text-center">
                Search for a product or service:
            </p>
            <v-text-field v-model="searchQuery" ref="searchInput" dense solo :color="primaryColor" hint="Search for an organization name, brand name, or product" placeholder="Search...">
                <template #prepend-inner>
                    <font-awesome-icon :icon="['fas', 'search']" fixed-width/> <!-- style="font-size: 20px;" -->
                </template>
            </v-text-field>
            <v-row no-gutters justify="center">
            <v-btn @click="submitForm" elevation="4" :style="primaryButtonStyle" class="mt-4">Search</v-btn>
            </v-row>
            <!-- <p class="mt-8 text-center" v-if="!isCustomDomain"><a :href="mainWebsiteURL">Create your own storefront</a></p> -->
            </v-form>
            <p class="mb-15"></p>
            <v-alert v-if="notFound">
                No results for your query.
            </v-alert>
            <template v-if="Array.isArray(productList) && productList.length > 0">
                <p class="text-overline mb-0 mt-2">Featured Products</p>
                <v-list>
                    <v-list-item v-for="product in productList" :key="product.id" @click="select(product)">
                        <v-list-item-content>
                            {{ product.name }}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
// import BrandList from '@/components/BrandList.vue';
// import BrandImage from '@/components/BrandImage.vue';

export default {
    components: {
        // BrandList,
        // BrandImage,
    },
    data: () => ({
        searchQuery: '',
        searchTimestamp: null,
        searchType: null,
        notFound: false,
        notFoundTimeout: null,
        productList: null,
    }),
    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryTextColor: 'primaryTextColor',
            mainWebsiteURL: 'mainWebsiteURL',
        }),
    },
    watch: {
        brandprofile(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.search();
            }
        },
        focus() {
            if (this.brandprofile) {
                this.search();
            }
        },
        $route(newValue, oldValue) {
            console.log(`route changed from ${JSON.stringify(oldValue.query)} to ${JSON.stringify(newValue.query)}`);
            // TODO: searchName , and maybe other ways to search like keywords
            this.searchType = newValue.query.type;
            this.search();
        },
    },
    methods: {
        reset() {
            this.notFound = false;
            if (this.notFoundTimeout) {
                clearTimeout(this.notFoundTimeout);
                this.notFoundTimeout = null;
            }
        },
        select(product) {
            if (this.brandselector === 'brandprofile') {
                this.$nav.push({ name: 'brand-catalog-item', query: { id: product.id } });
            } else {
                this.$nav.push({ name: 'main-catalog-item', query: { id: product.id } });
            }
        },
        async submitForm() {
            if (Number.isInteger(this.searchTimestamp) && this.searchTimestamp + 500 > Date.now()) {
                return;
            }
            this.searchTimestamp = Date.now();
            this.search();
        },
        async search() {
            try {
                if (this.$route.query.q !== this.searchQuery) {
                    // update URL in address bar
                    this.$nav.replace({ name: 'brand-catalog-search', query: { ...this.$route.query, q: this.searchQuery, t: Date.now() } });
                }
                this.reset();
                this.$store.commit('loading', { search: true });
                const response = await this.$client.site(this.brandprofile).product.search({ q: this.searchQuery, type: this.searchType });
                if (response?.list) {
                    this.productList = response.list;
                    console.log(`loaded product list: ${JSON.stringify(response.list)}`);
                }

                if (Array.isArray(this.productList) && this.productList.length === 0) {
                    this.notFound = true;
                    this.notFoundTimeout = setTimeout(() => { this.notFound = false; }, 15000); // clear message in 15 seconds
                }
            } catch (err) {
                console.error('failed to search', err);
                // this.error = true;
            } finally {
                this.$store.commit('loading', { search: false });
            }
        },
        // onSelectBrand({ alias }) {
        //     if (this.$route.query.intent === 'unsubscribe') {
        //         this.$router.push({ name: 'brand-view-profile', params: { alias }, hash: '#unsubscribe' });
        //     } else {
        //         this.$router.push({ name: 'brand-view-profile', params: { alias } });
        //     }
        // },
        /*
        onClickOrganization(item) {
            this.$router.push({ name: 'organization-dashboard', params: { organizationId: item.id } });
        },
        onClickDomain(item) {
            this.$router.push({ name: 'organization-view-domain', params: { organizationId: item.organization_id, domain: item.name } });
        },
        onClickDnsRecord(item) {
            this.$router.push({ name: 'organization-edit-dns', params: { organizationId: item.organization_id, domain: item.domain }, query: { type: item.type, key: item.key, value: item.value } });
        },
        */
    },
    created() {
        // listen for search events coming from any component (but specifically the navbar search button)
        this.$bus.$on('search', (q) => {
            this.searchQuery = q;
            this.$nextTick(() => {
                setTimeout(() => { this.$activateInput('searchInput'); }, 1);
            });
            if (q) {
                this.search();
            } else {
                this.reset();
            }
        });
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => { this.$activateInput('searchInput'); }, 1);
        });
        this.searchQuery = this.$route.query.q ?? '';
        this.searchType = this.$route.query.type;
        if (this.searchQuery || this.searchType) {
            this.search();
        }
    },
};
</script>
